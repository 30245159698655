module.exports = {
  // App Info
  appstore_link: "https://apps.apple.com/us/app/ana-ninja/id1620153149", // Enter App Store URL.
  playstore_link:
    "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.ananinja.lava&ddl=1&pcampaignid=web_ddl_1",
  google_analytics_ID: "GTM-TMKRMBVs", // Enter Google Analytics ID or ""
  presskit_download_link: "", // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: "screenshot", // "screenshot" or "video"
  app_url: "https://apps.apple.com/us/app/ana-ninja/id1620153149", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "Ana Ninja",
  app_price: "",
  app_description: "Grocery delivery for DOPEs",
  app_keywords: ["Grocery", "Ninja", "flink"],

  // Personal Info
  your_name: null,
  your_link: "https://apps.apple.com/us/app/ana-ninja/id1620153149",
  your_city: "Riyadh",
  email_address: null,
  linkedin_username: null,
  facebook_username: null,
  instagram_username: null,
  twitter_username: "",
  github_username: null,
  youtube_username: null,

  // Features List
  features: [
    {
      title: "Supermarket prices",
      description:
        "Over 2000 items, direct to your door, at normal supermarket prices!",
      fontawesome_icon_name: "store",
    },
    {
      title: "Ultra-fast delivery",
      description:
        "Our riders bring your groceries, direct to your door, in minutes.",
      fontawesome_icon_name: "clock",
    },
    {
      title: "Fresh & Organic",
      description:
        "Choose from our large selection of fresh and organic items.",
      fontawesome_icon_name: "carrot",
    },
  ],
  header_background: "rgba(0, 0, 0, 0.1)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(54, 59, 61, 0.8)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#1d63ea",
  app_title_color: "#ffffff",
  app_price_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color: "#666666",
  social_icons_background_color: "#e6e6e6",
  footer_text_color: "#666666",
}
